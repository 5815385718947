<template>
  <Vue3Lottie
    :class="hoverable ? `hoverable` : ``"
    :animationData="lottiePath"
    :height="height"
    :width="width"
    :autoPlay="autoPlay"
    :noMargin="noMargin"
    :key="index"
  />
</template>

<script>
import RobotBasicJSON from "../assets/lottie-animations/robot-cute-basic.json";
import RobotDarkJSON from "../assets/lottie-animations/robot-cute-dark.json";
import NiceRobotJSON from "../assets/lottie-animations/nice-bot.json";
import PurpleRobotJSON from "../assets/lottie-animations/purple-hi-bot.json";
import DialogFunnyJSON from "../assets/lottie-animations/dialog-funny-bot.json";
import CuttieBotJSON from "../assets/lottie-animations/cuttie-bot.json";
import GreenBallBotJSON from "../assets/lottie-animations/green-ball-bot.json";
import BobBotJSON from "../assets/lottie-animations/bob-bot.json";
import CatBotJSON from "../assets/lottie-animations/cat-bot.json";
import RobotJSON from "../assets/lottie-animations/robot-cute.json";
import YodaJSON from "../assets/lottie-animations/yoda.json";
import GandalfJSON from "../assets/lottie-animations/gandalf.json";
import Woman1JSON from "../assets/lottie-animations/woman-1.json";
import Woman2JSON from "../assets/lottie-animations/woman-2.json";

export default {
  name: "RobotComponent",
  props: {
    index: { type: Number, default: 0 },
    autoPlay: { type: Boolean, default: true },
    hoverable: { type: Boolean, default: false },
    height: { type: Number, default: 100 },
    width: { type: Number, default: 100 },
    noMargin: { type: Boolean, default: false },
  },
  data() {
    return {
      RobotBasicJSON,
      RobotDarkJSON,
      NiceRobotJSON,
      PurpleRobotJSON,
      DialogFunnyJSON,
      CuttieBotJSON,
      GreenBallBotJSON,
      BobBotJSON,
      CatBotJSON,
      RobotJSON,
      YodaJSON,
      GandalfJSON,
      Woman1JSON,
      Woman2JSON
    };
  },
  computed: {
    lottiePath() {
      switch (this.index) {
        case 0:
          return this.RobotJSON;
        case 1:
          return this.RobotDarkJSON;
        case 2:
          return this.NiceRobotJSON;
        case 3:
          return this.PurpleRobotJSON;
        case 4:
          return this.DialogFunnyJSON;
        case 5:
          return this.CuttieBotJSON;
        case 6:
          return this.GreenBallBotJSON;
        case 7:
          return this.BobBotJSON;
        case 8:
          return this.CatBotJSON;
        case 9:
          return this.RobotBasicJSON;
        case 10:
          return this.YodaJSON;
        case 11:
          return this.GandalfJSON;
        case 12:
          return this.Woman1JSON;
        case 13:
          return this.Woman2JSON;
        default:
          return this.RobotJSON;
      }
    },
  },
};
</script>

<style></style>
