import "vuetify/dist/vuetify.min.css";
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as styles from "vuetify/styles";
import * as labs from "vuetify/labs/VDataTable";

const myCustomMagentaTheme = {
  dark: false,
  colors: {
    background: "#FAFAFA",
    surface: "#FFFFFF",
    primary: "#2E012E",
    //primary: "#1e1e41",
    opaque: "rgba(100,100,100,0.3)",
    "primary-darken-1": "#1D001D",
    secondary: "#35126d",
    button: "#4a006a",
    "secondary-darken-1": "#6D3ECB",
    error: "#C62828",
    info: "#3F51B5",
    success: "#178A5C",
    warning: "#FF8F00",
  },
};

const vuetify = createVuetify({
  defaults: {
    VBtn: {
      elevation: 0,
      rounded: true,
    },
  },
  components: {
    ...components,
    ...labs,
    VSkeletonLoader
  },
  directives,
  styles,
  theme: {
    defaultTheme: "myCustomMagentaTheme",
    themes: {
      myCustomMagentaTheme,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

export default vuetify;
