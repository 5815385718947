<template>
  <svg-icon type="mdi" :size="size" :color="color" :path="iconPath"></svg-icon>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiDeleteOutline,
  mdiAccountCircle,
  mdiTranslate,
  mdiChevronRight,
  mdiMenu,
  mdiHomeOutline,
  mdiRobotExcitedOutline,
  mdiFileDocumentOutline,
  mdiCogOutline,
  mdiApps,
  mdiPlus,
  mdiMagnify,
  mdiChevronLeft,
  mdiChevronDown,
  mdiContentSave,
  mdiClose,
  mdiCloudUploadOutline,
  mdiEarth,
  mdiEarthOff,
  mdiImageOutline,
  mdiChatOutline,
  mdiHelpCircleOutline,
  mdiOpenInNew,
  mdiPencilBoxOutline,
  mdiCodeTags,
  mdiStarOutline,
  mdiStar,
  mdiSend,
  mdiTextBoxOutline,
  mdiCheckCircleOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiContentCopy,
  mdiPaperclip,
  mdiMessageText,
  mdiFileDownloadOutline,
  mdiFilePdfBox,
  mdiFileTableBox
} from "@mdi/js";

export default {
  name: "IconComponent",
  components: {
    SvgIcon,
  },
  props: {
    name: String,
    color: { type: String, default: "primary" },
    size: { type: Number, default: undefined },
  },
  data() {
    return {
      deleteIcon: mdiDeleteOutline,
      accountIcon: mdiAccountCircle,
      languageIcon: mdiTranslate,
      menuIcon: mdiMenu,
      rightIcon: mdiChevronRight,
      homeIcon: mdiHomeOutline,
      bibliothecasIcon: mdiFileDocumentOutline,
      integrationsIcon: mdiApps,
      configIcon: mdiCogOutline,
      searchIcon: mdiMagnify,
      backIcon: mdiChevronLeft,
      closeIcon: mdiClose,
      saveIcon: mdiContentSave,
      robotIcon: mdiRobotExcitedOutline,
      uploadIcon: mdiCloudUploadOutline,
      imageIcon: mdiImageOutline,
      addIcon: mdiPlus,
      chatIcon: mdiChatOutline,
      helpIcon: mdiHelpCircleOutline,
      openIcon: mdiOpenInNew,
      editIcon: mdiPencilBoxOutline,
      embedIcon: mdiCodeTags,
      starIconOutline: mdiStarOutline,
      starIcon: mdiStar,
      sendIcon: mdiSend,
      successIcon: mdiCheckCircleOutline,
      showIcon: mdiEyeOutline,
      hideIcon: mdiEyeOffOutline,
      copyIcon: mdiContentCopy,
      attachmentIcon: mdiPaperclip,
      publicIcon: mdiEarth,
      privateIcon: mdiEarthOff,
      documentIcon: mdiTextBoxOutline,
      minimizeIcon: mdiChevronDown,
      messageIcon: mdiMessageText,
      downloadIcon: mdiFileDownloadOutline,
      pdfIcon: mdiFilePdfBox,
      csvIcon: mdiFileTableBox
    };
  },
  computed: {
    iconPath() {
      switch (this.name) {
        case "delete":
          return this.deleteIcon;
        case "account":
          return this.accountIcon;
        case "language":
          return this.languageIcon;
        case "right":
          return this.rightIcon;
        case "menu":
          return this.menuIcon;
        case "home":
          return this.homeIcon;
        case "robot":
          return this.robotIcon;
        case "bibliothecas":
          return this.bibliothecasIcon;
        case "integrations":
          return this.integrationsIcon;
        case "config":
          return this.configIcon;
        case "search":
          return this.searchIcon;
        case "add":
          return this.addIcon;
        case "back":
          return this.backIcon;
        case "close":
          return this.closeIcon;
        case "save":
          return this.saveIcon;
        case "upload":
          return this.uploadIcon;
        case "image":
          return this.imageIcon;
        case "chat":
          return this.chatIcon;
        case "help":
          return this.helpIcon;
        case "open":
          return this.openIcon;
        case "edit":
          return this.editIcon;
        case "embed":
          return this.embedIcon;
        case "star":
          return this.starIcon;
        case "emptyStar":
          return this.starIconOutline;
        case "send":
          return this.sendIcon;
        case "success":
          return this.successIcon;
        case "show":
          return this.showIcon;
        case "hide":
          return this.hideIcon;
        case "copy":
          return this.copyIcon;
        case "attachment":
          return this.attachmentIcon;
        case "public":
          return this.publicIcon;
        case "private":
          return this.privateIcon;
        case "doc":
          return this.documentIcon;
        case "minimize":
          return this.minimizeIcon;
        case "message":
          return this.messageIcon;
        case "download":
          return this.downloadIcon;
        case "pdf":
          return this.pdfIcon;
        case "csv":
          return this.csvIcon;
        default:
          return null;
      }
    },
  },
};
</script>

<style></style>
